import { React, useEffect, useState, useRef } from 'react'; 

import demo from './chiuwawa.jpg';
import './App.css';
import Frags from './Frags';
import Frag from './Frag';
import Points from './Points';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

function App() {
  const PADDING = 100;
  const svgRef = useRef(null)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [height, setHeight] = useState(670);
  const [width, setWidth] = useState(1600);

  const [offsetHeight, setOffsetHeight] = useState(0);
  const [offsetWidth, setOffsetWidth] = useState(0);

  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(1600);
  const [top, setTop] = useState(0);
  const [bottom, setBottom] = useState(1600);
  
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [pos, setPos] = useState();
  const [points, setPoints] = useState([]);
  const [x_values, setXValues] = useState([]);
  const [y_values, setYValues] = useState([]);

  const [frags, setFrags] = useState([]);
  
  const onFragment = (e, l, t, h, w, p) => {

    const b = svgRef.current.getBoundingClientRect();

    // capture the current location of the mouse pointer
    const _x = e.clientX - b.x;
    const _y = e.clientY - b.y;

    // record the new points on each axis/ rulers/markings/tracking
    [_x, l, w].map((n) => x_values.push(n));
    [_y, t, h].map((m) => y_values.push(m));

    // create four quadrands from a center point of a square
    // splitting into quadrants
    // const frags =[
    //   [[left, top], [x,top], [x,y], [left,y], [left,top]],
    //   [[x,top], [right,top], [right,y], [x, y], [x,top]],
    //   [[left, y], [x,y], [x,bottom], [left,bottom], [left,y]],
    //   [[x,y], [right,y], [right,bottom], [x,bottom], [x,y]],
    // ];

    const topLeftQuad = () => {
      // from Left,Top (0,0) to X to X,Y
      return { x: l, y: t, top: t, left: l, bottom: _y, right: _x, width: _x-l, height: _y-t, p: p};
    }   
    const topRightQuad = () => {
      // from X,0 to Width to Width,Y
      return { x: _x, y: t, top: t, left: _x, bottom: _y, right: w, width: w-_x+l , height: _y-t,  p: p };
    }
    const bottomLeftQuad = () => {
      // from 0,Y to X to X,Height
      return { x: l, y: _y, top: _y, left: l, bottom: h-_y, right: _x, width: _x-l, height: h-_y+t, p: p };
    }
    const bottomRightQuad = () => {
      // from X,Y to Width to Width,Height (Right,Bottom)
      return { x: _x, y: _y, top: _y, left: _x, bottom: h-_y, right: w , width: w-_x+l, height: h-_y+t, p: p };
    }
    const topHalf = () => {
      // from Left,Top (0,0) to Width to Width,Y
      return { x: l, y: t, top: t, left: l, bottom: _y, right: w, width: w, height: _y-t, p: p };
    }
    const bottomHalf = () => {
      // from Left,Y (0,0) to Width to Width,Height
      return { x: l, y: _y, top: _y, left: l, bottom: h+t, right: w, width: w, height: h-_y+t, p: p };
    }
    const leftHalf = () => {
      // from Left,Y (0,0) to Width to Width,Height
      return { x: l, y: t, top: t, left: l, bottom: h, right: _x, width: _x-l, height: h, p: p };
    }
    const rightHalf = () => {
      // from Left,Y (0,0) to Width to Y,Height
      return { x: _x, y: t, top: t, left: _x, bottom: h, right: w, width: w-_x+l, height: h, p: p };
    }

    let f = [];
    let vert = null;

    if (_x - l < PADDING || _x + PADDING > w+l) vert = false;
    if (_y - t < PADDING || _y + PADDING > h+t) vert = true;

    if (vert === true) {
      f = [leftHalf(), rightHalf()];
    } else if (vert === false) {
      f = [topHalf(), bottomHalf()];
    } else {
      f = [topLeftQuad(),topRightQuad(),bottomLeftQuad(),bottomRightQuad()];
    }

    if (f.length){
      setX(_x);
      setY(_y);
      setPos([_x, _y]);
      points.push([_x, _y]);
      setPoints(points);
      setLeft(l);
      setTop(t);
      setRight(l+w);
      setBottom(t+h);
    }

    f.map((_f) => frags.push(_f));
    setFrags(frags);
  }

  const handleMouseMove = (e) => {
    if (!svgRef) return false;

    const b = svgRef.current.getBoundingClientRect();

    // capture the current location of the mouse pointer
    const _x = e.clientX - b.x;
    const _y = e.clientY - b.y;

    setX(_x);
    setY(_y);
  }

  useEffect(() => {
    const setFromEvent = (e) => handleMouseMove(e);
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);

  return (
    <div id="fragit" className="container-fluid">
      <div className="row">
        <div className="col-12">
          <svg viewBox={`${offsetWidth} ${offsetHeight} ${width} ${height}`} width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg" onMouseMove={handleMouseMove} ref={svgRef}>
            <g transform={`translate(${offsetWidth},${offsetHeight})`}>
              <image href={demo}/>
              <rect x="0" y="0" width={width} height={height} className="bounds"/>
              <Frags frags={frags} x={x} y={y} height={height} width={width} onFragment={onFragment} svgRef={svgRef} />
              <Points points={points} />
            </g>
          </svg>
        </div>
        <div className="col-12">

          <Accordion defaultActiveKey="0">

            <Accordion.Item>
              <Accordion.Header>All </Accordion.Header>
              <Accordion.Body>
                { frags.map((f,i) => (
                  <Card style={{ width: '18rem' }} className={`frag_${i} text-center`} key={i}>
                    <div variant="top" src={demo} style={{
                      background: `url(${demo}) no-repeat -${f.x}px -${f.y}px`,
                      display: 'inline-block',
                      height: f.height,
                      width: f.width,
                      maxHeight: "286px",
                      maxWidth: "286px"
                    }}> &nbsp; </div>
                    <Card.Body>
                      <Card.Title>[{f.x},{f.y}] {f.height} X {f.width}</Card.Title>
                      <Card.Text>
                        {JSON.stringify(f.height)}
                      </Card.Text>
                      <Button variant="primary">Mint</Button>
                    </Card.Body>
                  </Card>
                ))}
              </Accordion.Body>
            </Accordion.Item>

          { frags.filter((f) => f.p == null).map((f,i) => (
            <Accordion.Item eventKey={i} key={i}>
              <Accordion.Header>{i}) {frags.filter((f) => f.p == i).length ? `${frags.filter((f) => f.p == i).length} Frags` : `[${f.x},${f.y}] ${f.height} X ${f.width}`  }</Accordion.Header>
              <Accordion.Body>
                { frags.filter((f) => f.p == i).length 
                ? <Accordion defaultActiveKey="1">
                { frags.filter((f) => f.p == i).map((f,j) => (
                  <Accordion.Item eventKey={j} key={j}>
                    <Accordion.Header>{j}-{f.p}) [{f.x},{f.y}] {f.height} X {f.width} </Accordion.Header>
                    <Accordion.Body>
                      
                      <Card style={{ width: '18rem' }} className={`frag_${i} text-center`} key={i}>
                        <div variant="top" src={demo} style={{
                          background: `url(${demo}) no-repeat -${f.x}px -${f.y}px`,
                          display: 'inline-block',
                          height: f.height,
                          width: f.width,
                          maxHeight: "286px",
                          maxWidth: "286px"
                        }}> &nbsp; </div>
                        <Card.Body>
                          <Card.Title>[{f.x},{f.y}] {f.height} X {f.width}</Card.Title>
                          <Card.Text>
                            {JSON.stringify(f.height)}
                          </Card.Text>
                          <Button variant="primary">Mint</Button>
                        </Card.Body>
                      </Card>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
                </Accordion>
                : <Card style={{ width: '18rem' }} className={`frag_${i} text-center`} key={i}>
                  <div variant="top" src={demo} style={{
                    background: `url(${demo}) no-repeat -${f.x}px -${f.y}px`,
                    display: 'inline-block',
                    height: f.height,
                    width: f.width,
                    maxHeight: "286px",
                    maxWidth: "286px"
                  }}> &nbsp; </div>
                  <Card.Body>
                    <Card.Title>[{f.x},{f.y}] {f.height} X {f.width}</Card.Title>
                    <Card.Text>
                      {JSON.stringify(f.height)}
                    </Card.Text>
                    <Button variant="primary">Mint</Button>
                  </Card.Body>
                </Card>
                }
              </Accordion.Body>
            </Accordion.Item>
          ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default App;
