import { React, useEffect, useState } from 'react'; 
import Crosshair from './Crosshair';

function Frag({id, x, y, height, width, mousePos, onFragment, svgRef}) {
  const PADDING = 100;

  let _top = {};
  let _right = {};
  let _bottom = {};
  let _left = {};

  const [top, setTop] = useState(_top);
  const [right, setRight] = useState(_right);
  const [bottom, setBottom] = useState(_bottom);
  const [left, setLeft] = useState(_left);

  const [isOver, setOver] = useState(false);

  const onMouseOver = (e) => {
  	setOver(true);
  }

  const onMouseOut = (e,x,y,height,width) => {
  	// needs to reset to the original bounds of the box
  	// lines are made up of two (x,y) points X1,Y1 X2,Y2
  	// referred to by first and last
  	setTop({
  		first: { x: x, 		   y: y },
  		last:  { x: width+x, y: y }
  	}); // [[x,y],[width+x,y]]

  	setBottom({
  		first: { x: width+x, y: height+y }, 
  		last:  { x: x,		   y: height+y }
  	}); // [[width,height+y],[x,height+y]]

  	setRight({
  		first: { x: width+x, y: y },
  		last:  { x: width+x, y: height+y }
  	}); // [[width+x,y],[width+x,height+y]]

  	setLeft({
  		first: { x: x, y: height+y },
  		last:  { x: x, y: y }
  	}); // [[x,height+y],[x,y]]
  	
  	setOver(false);
  }

  // sets the position of the lines in the frags
  const setPos = () => {
  	_top = {
  		first: { x: x, 		   y: mousePos.y },
  		last:  { x: width+x, y: mousePos.y }
  	};
  	_bottom = {
  		first: { x: width+x, y: mousePos.y }, 
  		last:  { x: x,		   y: mousePos.y }
  	};
  	_right = {
  		first: { x: mousePos.x, y: y },
  		last:  { x: mousePos.x, y: height+y }
  	};
  	_left = {
  		first: { x: mousePos.x, y: height+y },
  		last:  { x: mousePos.x, Y: y }
  	};
  	removeFromMargins();
  }

  // remove frags that will be too close to the sides, or top/bottom
  const removeFromMargins = () => {
  	if (mousePos.x - x < PADDING || mousePos.x + PADDING > width+x) _right = right;
  	if (mousePos.y - y < PADDING || mousePos.y + PADDING > height+y) _top = top;
  }

  if (isOver && mousePos){
    setPos();
  }

  return (
  	<g className="frag" id={id}>
      <rect x={x} y={y} width={width} height={height} className="bounds" onMouseOver={onMouseOver} onMouseOut={(e) => onMouseOut(e,x,y,height,width)} onClick={(e) => onFragment(e,x,y,height,width,id)}/>
      { isOver 
      	? <>  
	      <Crosshair top={_top} right={_right} bottom={_bottom} left={_left} x={x} y={y} width={width} height={height} />
	    </>
	   : <></> }

    </g>
  );
}

export default Frag;
