import { React, useEffect, useState } from 'react'; 

function Points({points}) {
  return (
      <g>{ points.map((p,i) => {
          // debugger;
          return (<circle cx={p[0]} cy={p[1]} r={7} key={i} />)
        }
      ) }</g>
  );
}

export default Points;
