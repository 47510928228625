import { React, useEffect, useState } from 'react'; 
import Frag from './Frag';

function Frags({frags, x, y, height, width, onFragment, svgRef}) {

  return (<g className="frags">
        { frags.length 
          ? frags.map((f,i) => <Frag x={f.x} y={f.y} height={f.height} width={f.width} mousePos={{x: x, y: y}} key={i} id={i} onFragment={onFragment} svgRef={svgRef} />)
          : <Frag x={0} y={0} height={height} width={width} mousePos={{x: x, y:y}} onFragment={onFragment} />
        }
      </g>);
  }

export default Frags;
