import { React, useEffect, useState } from 'react'; 

function Crosshair({top, right}) {
  return (<>
	{ top.first ? <line x1={top.first.x} y1={top.first.y} x2={top.last.x} y2={top.last.y} className="top"/> : <></>}
	{ right.first ? <line x1={right.first.x} y1={right.first.y} x2={right.last.x} y2={right.last.y} className="right"/> : <></>}
  </>);
}

export default Crosshair;


